import { Auth } from "aws-amplify";
import { createContext, useContext, useEffect, useState } from "react";
import { logUserSignedIn, logUserSignedOut } from "src/shared/logger";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Employee, getEmployee } from "src/shared/api";
import { Duration, add } from "date-fns";
import { useInterval, useLocalStorage } from "usehooks-ts";
import { toast } from "sonner";

export interface User extends CognitoUser {
  attributes: {
    email: string;
    sub: string;
  };
}

interface AuthContextProps {
  loading: boolean;
  currentUser?: User;
  currentEmployee?: Employee | null;
  signIn: (currentUser: User) => Promise<void>;
  signOut: () => Promise<void>;
  refreshUser: () => Promise<void>;
  reloadUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
  loading: true,
  signIn: async () => {},
  signOut: async () => {},
  refreshUser: async () => {},
  reloadUser: async () => {},
});

type SessionExpirationConfig = {
  duration: Duration;
  checkInterval: number;
};

const expirationConfig: SessionExpirationConfig = {
  duration: { hours: 18 },
  checkInterval: 60_000,
};

const AuthProvider = (props) => {
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [currentEmployee, setCurrentEmployee] = useState<Employee | null>();
  const [loading, setLoading] = useState(true);

  const [sessionExpiration, setSessionExpiration] = useLocalStorage<
    Date | string | null
  >("sessionExpiration", null);

  useInterval(() => {
    if (
      currentUser &&
      sessionExpiration &&
      new Date(sessionExpiration) < new Date()
    ) {
      toast.error("Your session has expired. Please sign in again.", {
        duration: 86_400_000,
        closeButton: true,
      });
      signOut();
    }
  }, expirationConfig.checkInterval);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser: User = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          await signIn(currentUser);
        }
        const employee = await getEmployee();
        setCurrentEmployee(employee);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (currentUser: User) => {
    setCurrentUser(currentUser);
    const employee = await getEmployee();
    if (!sessionExpiration) {
      setSessionExpiration(add(new Date(), expirationConfig.duration));
    }
    setCurrentEmployee(employee);
    setLoading(false);
    toast.dismiss();
    logUserSignedIn(currentUser);
  };

  const signOut = async () => {
    logUserSignedOut();
    await Auth.signOut();
    setCurrentUser(undefined);
    setSessionExpiration(null);
  };

  const refreshUser = async () => {
    if (!currentUser) return;
    const employee = await getEmployee();
    setCurrentEmployee(employee);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        currentUser,
        currentEmployee,
        signIn,
        signOut,
        refreshUser,
      }}
      {...props}
    />
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
